import React from "react";

export const maxSafeNumber: number = 2147483647;

export const ActivityStatus: { [key: string]: string } = {
  true: "Активен",
  false: "Заблокирован",
};

export type StyledFC<Props = {}> = React.FC<Props & { className?: string }>;
export type Customer = {
  id: number;
  firstName: string;
  lastName: string;
  patronymic: string;
  birthDay: string;
  phoneNumber: string;
  email: string;
  lastActive: string;
  tickets: number;
  isActive: boolean;
};

export type AuthCookie = {
  accessToken: string;
  refreshToken: string;
  userName: string;
};

export type UserCookie = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  phoneNumber: string;
  isActive: boolean;
  lastActive: string;
  login: string;
  roles: string[];
  city: { id: number; name: string };
};

export type AlertData = { text: string; type: AlertType };

export type AlertContextType = {
  showAlert: (alertData: AlertData) => void;
};

export enum AlertType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export type AlertProps = {
  id: number;
  isDeleted: boolean;
} & AlertData;

export type Employee = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  lastActive: string;
  isActive: boolean;
  login: string;
  patronymic: string;
  phoneNumber: string;
  roles: string[];
  roleId: number;
};

export type EmployeeCreate = {
  lastName: string;
  firstName: string;
  patronymic: string;
  roleId: number;
  password: string | null;
  cityId?: number;
};

export type EmployeeEdit = {
  lastName: string;
  firstName: string;
  patronymic: string;
  roleId: number;
  generateNewLogin: boolean;
  cityId?: number;
};

export type Category = {
  id: number;
  name: string;
  value?: string;
  description?: string;
  position: number;
  isActive: boolean;
  showInMenu: boolean;
};

export type PromotionRow = {
  id: number;
  name: string;
  price: number | null;
  discount: number | null;
  productSetsList: ProductSet[];
  description?: string;
  isActive: boolean;
  goByClick: boolean;
  isPickupOnly: boolean;
  thumbnail?: string;
  mainImgName?: string;
};

export type OrderPromotionProductSetsList = {
  promotionCategoryId: number;
  productIds: number[];
  variantIds: number[];
  count: number;
  id: number;
  isFree: boolean;
};
export type OrderPromotion = {
  id: number;
  name: string;
  price: number | null;
  discount: number | null;
  description?: string;
  isActive: boolean;
  goByClick: boolean;
  thumbnail?: string;
  mainImgName?: string;
  productSetsList: OrderPromotionProductSetsList[];
};

export type Promotion = {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  thumbnail: string;
  discount: number;
  price: number | string;
  position: number;
  availableOnBanner: boolean;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  mainImgName: string;
  sliderImgName: string;
  worksOnHoliday: boolean;
  productSetsList: ProductSet[];
  worksOnPreHoliday: boolean;
  promotionDaysList: number[];
  goByClick: boolean;
};

export type PromotionCategory = {
  id: number;
  name: string;
  description?: string;
  isActive: boolean;
  category: { id: number };
  products: ProductRow[];
};

export type PromotionCategoryRibbon = PromotionCategory & {
  isChecked: boolean;
};

export type ProductSet = {
  promotionCategoryId: number;
  productId?: number;
  count: number;
  isFree: boolean;
  productSetVariantsList: {
    price: number;
    variantId: number;
    values: {
      optionId: number;
      optionData: string;
      optionValueId: number;
      optionValueData: string;
    }[];
  }[];
};

export type CategoryCreate = {
  name: string;
  description: string;
  position: number;
};

export type dropdownMenuOption = {
  icon: JSX.Element;
  text: string;
  event: () => void;
};

export type Product = {
  ImgFile: any;
  Name: string;
  CategoryId: number;
  Description: string;
  Price: number;
  Position: number;
  Weight: number;
  Calories: number;
  Proteins: number;
  Fats: number;
  Carbohydrates: number;
  HasFreeSauce: boolean;
  IsRecommended: boolean;
};
export type DispatcherProduct = {
  productId: number;
  quantity: number;
  variantId: number;
  price: number;
  options: { [id: string]: string[] };
  ingredientsToRemove: Array<{ id: number; name: string }>;
  ingredientsToAdd: Array<{
    ingredientId: number;
    quantity: number;
    name: string;
  }>;
};
export type ProductRowVariant = {
  variantId: number;
  price: number;
  weight?: number;
  values: {
    optionId: number;
    optionData: string;
    optionValueData: string;
    optionValueId: number;
  }[];
};

export type ProductRow = {
  id: number;
  name: string;
  categoryId: number;
  categoryName?: string;
  description: string;
  price: number | string;
  isDeleted: boolean;
  position: number;
  weight: string;
  nutrition: {
    proteins: number;
    fats: number;
    carbohydrates: number;
    calories: number;
  };
  hasVariants: boolean;
  imgName?: string;
  calories: string;
  promotionCategoryId?: number;
  proteins: string;
  isOptionsError?: boolean;
  variants: ProductRowVariant[];
  fats: string;
  thumbnail: string;
  carbohydrates: string;
  hasFreeSauce: boolean;
  isRecommended: boolean;
  isActive: boolean;
  isPickupOnly?: boolean;
};

export type PromotionCategoryProductRow = ProductRow & {
  count: number;
  isChecked: boolean;
};

export type ProductEdit = ProductRow & {
  filters: { id: number; name: string }[];
  ingredientsForAdding: {
    id: number;
    name: string;
    quantity: number;
    isActive?: boolean;
  }[];
  ingredientsForRemoving: { id: number; name: string; isActive?: boolean }[];
};

export type PCData = {
  imgFile: string | File;
  name: string;
  categoryId: string;
  description: string;
  price: string;
  position: string;
  weight: string;
  filtersToAdd?: number[] | string;
  calories: string;
  proteins: string;
  fats: string;
  carbohydrates: string;
  hasFreeSauce: boolean;
  isRecommended: boolean;
  isActive: boolean;
};

export type PCDataEdit = {
  imgName?: string;
  name: string;
  categoryId: number;
  description?: string;
  price: string;
  position: number;
  weight?: number;
  calories?: number;
  addIngredientsToRemoveList?: number[];
  removeIngredientsFromRemoveList?: number[];
  addIngredientsToAddList?: number[];
  removeIngredientsFromAddList?: number[];
  filtersToAdd?: number[];
  filtersToRemove?: number[];
  proteins?: number;
  fats?: number;
  carbohydrates?: number;
  hasFreeSauce?: boolean;
  isRecommended?: boolean;
  isActive?: boolean;
};

export type LabelRibbonData = {
  id: number;
  name: string;
  isChecked: boolean;
  isActive?: boolean;
  maxQuantity?: number;
  quantity?: number;
  categories?: IngredientCategories[];
};

export type IngredientCategories = {
  categoryId: number;
  price: number;
  weight: number;
  isCommon: boolean;
  variants: {
    price: number;
    weight: number;
    variantId: number;
    values: { optionId: number; optionValueId: number }[];
  }[];
};
export type AddedIngredient = {
  id: number;
  name: string;
  isFree: boolean;
  position: number;
  isChecked: boolean;
  isActive: boolean;
  categories: null;
  quantity: number;
  maxQuantity: number;
};
export type Ingredient = {
  id: number;
  name: string;
  maxQuantity?: number;
  position: number;
  isAvailable?: boolean;
  isActive?: boolean;
  categories: IngredientCategories[];
};

export type IngredientCreate = {
  name: string;
  position: number;
  isAvailable: boolean;
};

export type IngredientCategory = {
  id: number;
  name: string;
  isChecked: boolean;
  isVariantsOpen: boolean;
  isActive?: boolean;
  isCommon: boolean;
  price: number | string;
  weight: number | string;
  isOptionsOpen: boolean;
  options: Option[];
};

export type OptionVariant = {
  id: number;
  value: string;
  price?: string | number;
  weight?: string | number;
  isChecked?: boolean;
};
export type CurrentOption = {
  id: number;
  name: string;
  isChecked: boolean;
  categories: number[];
  impactOnPrice: boolean;
  optionVariants: {
    id: number;
    value: string;
    price?: string | number;
    weight?: string | number;
    isChecked?: boolean;
  }[];
};
export type Option = {
  id: number;
  value: string;
  isActive?: boolean;
  categories: number[];
  optionVariants: OptionVariant[];
  impactOnPrice?: boolean;
  isChecked?: boolean;
};

export type OptionData = {
  id: number;
  value: string;
  type: number;
  isActive: boolean;
  impactOnPrice: boolean;
};

export type OptionType = {
  id: number;
  description: string;
  value: "info" | "list" | "segment";
  isActive: boolean;
  impactOnPrice: boolean;
};

export type OptionTypes = {
  id: number;
  description: string;
  value: "info" | "list" | "segment";
};

export type OptionCategory = {
  description: string;
  id: number;
  isActive: true;
  name: string;
};

export type OptionTypeCreate = {
  id: number;
  value: string;
  categories?: OptionCategory[];
  type: number;
  isActive: boolean;
  impactOnPrice: boolean;
  optionVariants: { id: number; value: string; description: string }[];
};

export type Banner = {
  id: number;
  name: string;
  position: number;
  thumbnail: string;
  categoryId: number | null;
  productId: number | null;
  isActive: boolean;
};

export type BannerCreate = {
  name: string;
  imgName: string;
  categoryId?: string;
  productId?: string;
  isActive: string;
};

export type PromotionCreate = {
  name: string;
  availableOnBanner: boolean;
  description: string;
  mainImgName: string;
  sliderImgName: string;
  isActive: boolean;
  worksOnHoliday: boolean;
  worksOnPreHoliday: boolean;
  startDate: string | undefined;
  startTime: string | undefined;
  endDate: string | undefined;
  endTime: string | undefined;
  promotionDaysList: number[];
  productSetList: {
    promotionCategoryId: number;
    productId?: number;
    count: number;
    isFree: boolean;
    variantList: number[];
  }[];
};

export type OrderRow = {
  id: number;
  sourceId: number;
  createdAt: string;
  customerPhoneNumber: string;
  orderNumber: string;
  status: string;
  totalCost: number;
  createdBy: string;
  readyTime: string;
  customerAddress: string;
  shop: number;
  shopName: string;
  waitingTime: string | null;
};

export type OrderCommonPromotionItem = {
  id: number;
  name: string;
  promotionCategoryId: number;
  ingredientsToAdd?: {
    name: string;
    quantity: number;
    ingredientId: number;
  }[];
  ingredientsToRemove?: {
    id?: number;
    name: string;
    quantity: number;
    ingredientId: number;
  }[];
  isOptionsError?: boolean;
  options: { [id: string]: string[] };
  variantId: number;
  productId: number;
  quantity: number;
  totalPrice: number | string;
};
export type OrderCommonPromotion = {
  promotionId: number;
  name: string;
  productId: number;
  quantity: number;
  totalCost: number | string;
  items: OrderCommonPromotionItem[];
};
export type OrderCommonPromoCodeItem = {
  count: number;
  name: string;
  productId: number;
  promotionCategoryId: number;
  variant: PresentVariantItem | null;
};
export type PresentVariantValue = {
  optionData: string;
  optionId: number;
  optionValueData: string;
  optionValueDescription?: string;
  optionValueId: number;
};
export type PresentVariantItem = {
  values: PresentVariantValue[];
  variantId: number;
};
export type OrderCommonPromoCode = {
  [key: string]: number | string | OrderCommonPromoCodeItem[] | undefined;
  discount?: number;
  promocodeValueId?: number;
  id: number;
  name: string;
  presents: OrderCommonPromoCodeItem[];
  value: string;
};
export type OrderCommonItem = {
  id: number;
  ingredientsToAdd?: {
    name: string;
    quantity: number;
    ingredientId: number;
  }[];
  ingredientsToRemove?: {
    id?: number;
    name: string;
    quantity: number;
    ingredientId: number;
  }[];
  isOptionsError?: boolean;
  name: string;
  variantId: number;
  options: { [id: string]: string[] };
  productId: number;
  quantity: number;
  totalPrice: number | string;
};
type OrderCommon = {
  deletedAt?: string;
  waitingTime?: string;
  createdAt: string;
  id: number;
  oddMoney: number;
  accruedTickets: number;
  orderComment: string;
  adminCommentary: string;
  amountToBePaid: number;
  canCancel: boolean;
  customer: {
    id: number;
    lastName: string;
    firstName: string;
    patronymic: string;
    phoneNumber: string;
    email: string;
    birthDay: string;
  };
  deliveryAddress?: {
    addressComment: string;
    apartment: string;
    kladrId: string;
    city: string;
    code: string;
    deliveryAddress: string;
    entrance: string;
    floor: string;
    house: string;
    id?: number;
    isAddressError: boolean;
    street: string;
    streetType: string;
  };
  readyTime: string;
  paymentStatus: boolean;
  orderNumber: string;
  payment: {
    state: any;
    GatewayName: string;
    externalPaymentId: number;
  };
  paymentType: {
    type: string;
    description: string;
    isOnline: boolean;
    id: number;
  };
  promotions: OrderCommonPromotion[];
  promoCode: OrderCommonPromoCode;
  items: OrderCommonItem[];
  shop?: { name: string; id: number };
  source: { sourceId: number; description: string };
  spentTickets: number;
  totalPrice: number;
};

export type OrderView = OrderCommon & {
  status: { id: number; description: string };
};
export type OrderEdit = {
  number: number;
  status: number;
  shopId: number;
  addressId?: number;
  customerAddress?: {
    commentary: string;
    apartment: string;
    city: string;
    code: string;
    deliveryAddress: string;
    entrance: string;
    floor: string;
    house: string;
    customerId: number;
    isAddressError: boolean;
    street: string;
    kladrId: number;
  };
  deliveryType?: number;
  spentTickets: number;
  paymentTypeId: number;
  readyTime: string;
  adminCommentary: string;
  promotions?: {
    id: number;
    name: string;
    productId: number;
    quantity: number;
    totalCost: number;
    items: {
      id: number;
      name: string;
      options: { [id: string]: string[] };
      productId: number;
      quantity: number;
      totalPrice: number | string;
    }[];
  }[];
  items?: {
    productId: number;
    variantId: number;
    ingredientsToAdd?: {
      id: number;
      name?: string;
      quantity: number;
      ingredientId: number;
    }[];
    ingredientsToRemove?: number[];
    name?: string;
    quantity: number;
    totalPrice?: number;
  }[];
};

export type PaymentEdit = {
  spentTickets: number;
  paymentTypeId: number;
  oddFrom?: number | null;
  addressId?: number;
  receivingMethodId: 0 | 1;
};

export type ItemsEdit = {
  readyTime: string;
  promotions: {
    promotionId: number;
    quantity: number;
    promotionItems: {
      productId: number;
      variantId: number;
      quantity: number;
    };
  }[];
  items: {
    productId: number;
    quantity: number;
    variantId?: number;
    ingredientsToAdd?: {
      quantity: number;
      ingredientId: number;
    }[];
    ingredientsToRemove?: number[];
  }[];
};

export type AddressEdit = {
  customerId: number;
  orderId: number;
  kladrId?: number;
  kladrObjectCoordinateId?: number;
  house: string;
  entrance: number;
  floor: number;
  apartment: number;
  code?: number;
  addressComment: string;
  isAddressError: boolean;
};
export type Address = {
  id: number;
  fullName: string;
  shopId: number;
  aroundTheClockShopId: number;
};
export type OrderAddress = {
  id: number;
  value: string;
  shopId: number;
  shop24Id: number;
};
export type MinMaxInterval = {
  min: Date;
  max: Date;
};
export type OrderMainEdit = {
  shopId?: number;
  addressId?: number;
  spentTickets: number;
  readyTime: string;
  adminCommentary: string;
  receivingMethodId: 0 | 1;
};

export type StreetSearchResponse = {
  id?: number;
  kladrId?: number;
  name?: string;
  kladrObjectTypeId?: number;
  klardObjectTypeId?: number;
  fullName?: string;
  entrance?: string;
  apartment?: string;
  code?: string;
  commentary?: string;
  floor?: string;
  house?: string;
  shopId?: number;
  street?: string;
};

export type PromocodeListed = {
  id: number;
  name: string;
  forApplications: boolean;
  forSite: boolean;
  individual: boolean;
  type: number;
};

export type CodeProductSet = {
  productSetId: number | undefined;
  promotionCategoryId: number | null;
  productId: number | undefined | null;
  promoCodeId: number | undefined | "new";
  count: number;
  variantsList: Array<{
    variantId: number;
    values: Array<{
      optionId: number;
      optionData: string;
      optionValueId: number;
      optionValueData: string;
    }>;
  }>;
};

export type Promocode = {
  name: string;
  forApplications: boolean;
  forSite: boolean;
  discount: number;
  forPickup: boolean;
  value: string;
  forDelivery: boolean;
  minPrice: number;
  forBirthday: boolean;
  forFirstOrder: boolean;
  individual: boolean;
  lifetime: number;
  presents: Array<CodeProductSet>;
  productSets: Array<CodeProductSet>;
};

export type FormPromoCode = {
  customerId: number;
  isRus: boolean;
  isEng: boolean;
  isNum: boolean;
  valuesCount: string;
  startDate: any;
};

export type PromoCodeValues = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: PromoCodeValuesItem[];
  pageIndex: number;
  totalItems: number;
  totalPages: number;
};

export type PromoCodeValuesItem = {
  startDate: string;
  customerPhoneNumber: string;
  expirationDate: string;
  lifetime: number;
  promoCodeValueId: number;
  value: string;
};

export type newPromoCodeList = { client: string; code: string }[];

export type PromocodeHistoryEntry = {
  promoCodeValue: string;
  customerId: number;
  customerPhoneNumber: number;
  orderId: number | null;
  orderDate: string;
};

export type GiftsResponseEntry = {
  count: number;
  product: null | {
    categoryId: number;
    description: string;
    name: string;
    price: number;
    productId: number;
  };
  promotionCategory: {
    categoryId: number;
    description: string;
    name: string;
    promotionCategoryId: number;
  };
  variants: Array<{
    variantId: number;
    values: Array<{
      optionData: string;
      optionId: number;
      optionValueData: string;
      optionValueDescription: string;
      optionValueId: number;
    }>;
  }>;
};
