import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import {
  Customer,
  CodeProductSet,
  GiftsResponseEntry,
  ProductRow,
  ProductSet,
  Promocode,
  PromotionCategory,
} from "../../../_shared/types";
import API from "../../../_shared/axios";
import { Autocomplete } from "@material-ui/lab";
import {
  alertError,
  alertSuccess,
  getClientName,
  getErrorMsg,
} from "../../../_shared/utils";
import { throttle } from "lodash";
import styles from "../../DispatcherPage/OrderForm/index.module.scss";
import GiftsInfo from "./GiftsInfo";
import Typography from "@material-ui/core/Typography";
import NewCustomerForm from "../../UsersPage/CustomersPage/NewCustomerForm";
import { AlertContext } from "../../_shared/ToastList";

const useStyles = makeStyles({
  rootGrid: {
    height: "100%",
  },
  main: {
    marginTop: "50px",
    padding: "20px",
    width: "900px",
    backgroundColor: "#fdfdfd",
    boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
  },
  fieldsContainer: {
    height: "100px",
    padding: "10px",
    margin: "20px 0 20px 0",
  },
  inputCode: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "300px",
  },
});

export type PromocodeExtra = Omit<Promocode, "presents" & "productSets"> & {
  presents: Omit<Promocode["presents"], "productId"> & { productId: number[] };
  productSets: Omit<Promocode["productSets"], "productId"> & {
    productId: number[];
    productSetVariantsList: ProductSet[];
  };
};

const CashierPromocode: FC = ({}) => {
  const classes = useStyles();
  const alertContext = useContext(AlertContext);

  const [customerString, setCustomerString] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [promocodeValue, setPromocodeValue] = useState("");
  const [chosenCustomer, setChosenCustomer] = useState<Customer | null>();
  const [newCachedCustomer, setNewCachedCustomer] = useState<Customer>();
  const [customersPage, setCustomersPage] = useState(1);
  const [customers, setCustomers] = useState<Array<Customer>>([]);
  const [promocodeId, setPromocodeId] = useState<number>(0);
  const [promocodeData, setPromocodeData] = useState<PromocodeExtra>();
  const [promocodeValueId, setPromocodeValueId] = useState();
  const [products, setProducts] = useState<{ [key: string]: ProductRow }>({});
  const [error, setError] = useState<string>();
  const [completed, setCompleted] = useState(false);
  const [showNewCustomerForm, setShowNewCustomerForm] = useState(false);

  const fetchCustomers = useCallback(() => {
    const paramString = customerString
      ? `pageNumber=${customersPage}&FilterParams[0].ColumnName=phoneNumber&FilterParams[0].FilterOption=3&FilterParams[0].filterValue=${customerString}`
      : `pageNumber=${customersPage}`;
    API.get(`/customers?${paramString}`)
      .then((resp) => {
        setCustomers((state) => [...state, ...resp.data.items]);
      })
      .catch((e) => console.error(e));
  }, [customerString, customersPage]);
  // 7xr4ld9

  const onCreateNewUser = (data: {
    phoneNumber: string;
    lastName: string;
    firstName: string;
    patronymic: string;
    email?: string;
    birthDay?: string;
  }) =>
    new Promise((resolve, reject) =>
      API.post("/customers", data)
        .then(({ data }) => {
          if (data) {
            API.get(`/customers/${data}`)
              .then((resp) => {
                alertSuccess(alertContext, "Клиент добавлен");
                setChosenCustomer(resp.data);
                setNewCachedCustomer(resp.data);
              })
              .finally(() => {
                fetchCustomers();
                setShowNewCustomerForm(false);
              });
          } else {
            fetchCustomers();
            setShowNewCustomerForm(false);
          }
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка добавления клиента");
          reject(error);
        })
        .finally(() => {
          setCustomerString("");
          setInputValue("");
          setChosenCustomer(null);
        })
    );

  const applyCode = () => {
    if (promocodeId && chosenCustomer && promocodeValueId) {
      API.patch(
        `promoCodes/${promocodeValueId}/offline?customerId=${chosenCustomer.id}`
      )
        .then((resp) => {
          setPromocodeValue("");
          setCompleted(true);
          setCustomerString("");
        })
        .catch((e) => {
          if (e.response) {
            setError("Ошибка применения промокода");
          }
        });
    }
  };

  const fetchPromocode = useCallback(async () => {
    if (chosenCustomer && promocodeValue !== "") {
      try {
        const terms = await API.get(
          `promocodes/${promocodeValue}/terms?customerId=${chosenCustomer.id}`
        );
        setPromocodeId(terms.data.promoCodeId);
        setPromocodeValueId(terms.data.promoCodeValueId);
        const promocodeReq = await API.get(
          `promoCodes/${terms.data.promoCodeId}`
        );
        const prodIds: Array<number> = [];
        const reqProds = [...promocodeReq.data.productSets];

        const promoCatsFetch1: Array<PromotionCategory> = await Promise.all(
          promocodeReq.data.productSets.map(
            async (i: CodeProductSet, index: number) => {
              if (!i.productId) {
                const promoReq = await API.get(
                  `promotionCategories/${i.promotionCategoryId}`
                );
                reqProds[index].productId = promoReq.data.products.map(
                  (p: ProductRow) => p.id
                );
                prodIds.push(...reqProds[index].productId);
                return promoReq.data;
              } else {
                prodIds.push(i.productId);
                reqProds[index].productId = [i.productId];
              }
            }
          )
        );

        promoCatsFetch1
          .filter((p) => p)
          .forEach((pr: PromotionCategory) => {
            pr.products.forEach((p: ProductRow) => prodIds.push(p.id));
          });
        // TODO optimize fetching
        const giftProds = [...promocodeReq.data.presents];

        const promoCatsFetch2: Array<PromotionCategory> = await Promise.all(
          promocodeReq.data.presents.map(
            async (i: CodeProductSet, index: number) => {
              if (!i.productId) {
                const promoReq = await API.get(
                  `promotionCategories/${i.promotionCategoryId}`
                );
                giftProds[index].productId = promoReq.data.products.map(
                  (p: ProductRow) => p.id
                );
                prodIds.push(...giftProds[index].productId);
                return promoReq.data;
              } else {
                prodIds.push(i.productId);
                giftProds[index].productId = [i.productId];
              }
            }
          )
        );
        console.log(promoCatsFetch2);
        promoCatsFetch2
          .filter((p) => p)
          .map((pr: PromotionCategory) => {
            pr.products.map((p: ProductRow) => prodIds.push(p.id));
          });

        promocodeReq.data.productSets.map((ps: CodeProductSet) =>
          // @ts-ignore
          ps.productId
            ? Array.isArray(ps.productId)
              ? prodIds.push(...ps.productId)
              : prodIds.push(ps.productId)
            : void 0
        );
        console.log(promocodeReq.data.productSets);
        const prodsData: { [key: string]: ProductRow } = {};

        await Promise.all(
          prodIds.map(async (p) => {
            const resp = await API.get(`products/${p}`);
            if (resp.data) {
              prodsData[String(p)] = resp.data;
            }
          })
        );

        promocodeReq.data.productSets.map((ps: any) => {
          if (ps.productSetVariantsList.length === 0) {
            ps.productSetVariantsList = prodsData[ps.productId[0]];
          }
        });
        promocodeReq.data.presents.map((ps: any) => {
          if (ps.variantsList.length === 0) {
            ps.productSetVariantsList = prodsData[ps.productId[0]];
          }
        });

        setCompleted(false);
        setProducts(prodsData);
        setPromocodeData({
          ...promocodeReq.data,
          productSets: reqProds,
          presents: giftProds,
        });
        setError(undefined);
      } catch (e) {
        if (e.response) {
          if (e.response.status === 400) {
            // setError("Промокод уже был использован");
            setError(e.response.data.errors[0]);
          } else if (e.response.status === 404) {
            setError(getErrorMsg(e.response, "Промокод не найден"));
            // setError(e.response.data.errors[0]);
          }
        }
      }
    }
  }, [chosenCustomer, promocodeValue]);

  useEffect(() => {
    fetchCustomers();
  }, [customerString, customersPage]);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (newCachedCustomer) {
      setChosenCustomer(newCachedCustomer);
      setNewCachedCustomer(undefined);
    }
  }, [customers]);

  return (
    <Box>
      {showNewCustomerForm && (
        <NewCustomerForm
          onlyModal
          onSend={onCreateNewUser}
          onClose={() => {
            setShowNewCustomerForm(false);
            setChosenCustomer(null);
            setInputValue("");
          }}
        />
      )}
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justify={"center"}
        className={classes.rootGrid}
      >
        <Box className={classes.main}>
          <Typography variant={"h4"}>Проверка промокода</Typography>
          <Paper>
            <Grid
              container
              justify={"space-around"}
              direction={"row"}
              className={classes.fieldsContainer}
            >
              <Autocomplete
                className={classes.inputCode}
                size="medium"
                options={[
                  {
                    id: -1,
                    phoneNumber: "0",
                    lastName: "Добавить нового клиента",
                  },
                  ...customers,
                ]}
                value={chosenCustomer}
                onChange={(e, newValue) => {
                  if (newValue) {
                    if (newValue.id !== -1) {
                      setCustomerString(newValue.phoneNumber);
                      // @ts-ignore
                      setChosenCustomer(newValue);
                    } else {
                      setShowNewCustomerForm(true);
                      setCustomerString("");
                    }
                  }
                }}
                getOptionLabel={(option) =>
                  option.id === -1
                    ? option.lastName
                    : // @ts-ignore
                      getClientName(option) ?? ""
                }
                inputValue={inputValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={"Номер клиента"}
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                  setCustomerString(
                    newInputValue.replace(/[^a-zA-Z0-9а-яА-Я]/g, "")
                  );
                }}
                filterOptions={(options) => {
                  return options.filter((option) => {
                    if (option.id === -1) {
                      return true;
                    } else {
                      if (
                        `${option.lastName}${option.phoneNumber}`.includes(
                          inputValue
                        )
                      ) {
                        return true;
                      }
                    }
                    return false;
                  });
                }}
                ListboxProps={{
                  style: { scrollbarWidth: "none" },
                  onScroll: throttle((event: React.SyntheticEvent) => {
                    const listboxNode = event.currentTarget;
                    if (
                      listboxNode.scrollTop + listboxNode.clientHeight ===
                      listboxNode.scrollHeight
                    ) {
                      setCustomersPage((pages) =>
                        pages < 10 ? pages + 1 : pages
                      );
                    }
                  }),
                }}
              />
              <TextField
                size={"medium"}
                label={"Промокод"}
                variant={"outlined"}
                style={{ height: "60px", margin: "10px 0 10px 0" }}
                value={promocodeValue}
                className={classes.inputCode}
                onChange={(e) => setPromocodeValue(e.target.value)}
              />
              <Button
                style={{ height: "56px", margin: "10px 0 10px 0" }}
                variant={"contained"}
                color={"primary"}
                disabled={!chosenCustomer || promocodeValue === ""}
                onClick={fetchPromocode}
              >
                Проверить
              </Button>
            </Grid>
          </Paper>
          {promocodeData && !completed && !error && (
            <>
              <GiftsInfo prods={products} data={promocodeData} />
              <Button
                color={"primary"}
                variant={"contained"}
                onClick={applyCode}
                style={{ marginLeft: "15px" }}
              >
                Применить
              </Button>
            </>
          )}
          {completed ? (
            <Typography variant={"h4"}>
              {"Промокод был отмечен использованым"}
            </Typography>
          ) : (
            error && <Typography variant={"h5"}>{error}</Typography>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default CashierPromocode;
